main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}
.bg-c{
  background-color: #cfb59e!important;
  
}
.search-color{
  border-color: #ff00fb7f ;
  color: #ff00fb7f ;
background-color: #cfb59e;
}
.search-color:hover{
  background-color: transparent;
}
@media (min-width:768px){
  .larg-m{
margin-right: 150px;
}
}


@media (min-width:768px){
  img.carouselImg{
    max-width:40%; /* Ensure the image doesn't overflow its container */
    height: auto;    /* Maintain the aspect ratio */
    display: block;
  
  }
}
.logo{
  width: 100px;
  height:auto
}


.size-button {
  background-color: white;
  border: 1px solid #ccc;
  color: black;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
}

.size-button.selected {
  background-color: blue;
  color: white;
}

.size-remove-button {
  background-color: red;
  color: white;
  padding: 5px;
  border: none;
  cursor: pointer;
}

/* category */
.icon-container img {
  width: 40px;
  height: 40px;
  object-fit: contain; /* لتجنب تشويه الصورة */
}
.category-card:hover {
  transition: all 0.5s ease; /* إضافة انسيابية في التحول */
  background-color: #cfb59e;
  color: #fff !important;
  transform: translateY(-5px); /* رفع البطاقة عند التمرير */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* إضافة ظل */
}
.porduct-card:hover {
  transition: all 0.5s ease; /* إضافة انسيابية في التحول */
  transform: translateY(-5px); /* رفع البطاقة عند التمرير */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* إضافة ظل */
}
/* .card-body {
  transition: transform 0.3s ease;
} */
h1 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
}

.card-title {
  font-size: 1.1rem;
  color: #444;
}
